import { Badge } from 'components/catalyst/badge'
import { Card } from 'components/catalyst/card'
import { Text } from 'components/catalyst/text'
import { RouteNames } from 'const/navigation'
import { Link } from 'react-router-dom'

type Props = {
  dataset: any
}

export function DatasetItemGrid(props: Props) {
  return (
    <Link to={RouteNames.DatasetDetail.get(props.dataset.name)} className="group">
      <Card className="max-w-[520px] space-y-3">
        <div className="flex items-center">
          <Text className="flex-1 font-semibold text-zinc-900 transition-all group-hover:text-sky-600 dark:text-zinc-300 group-hover:dark:text-sky-400 sm:text-base">
            Customer Data 2024
          </Text>

          <Badge>CSV</Badge>
        </div>

        <Text className="text-xs sm:text-xs">1.45 GB</Text>
        <Text className="text-xs sm:text-xs">Last update: 15min. ago</Text>
      </Card>
    </Link>
  )
}
