import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table'
import { StackedItemSkeleton } from 'components/skeletons'
import { RouteNames } from 'const/navigation'
import { EllipsisVertical } from 'lucide-react'
import { Index } from '../types'

type Props = {
  loading: boolean
  indexes: Index[]
}

export function IndexesStackLayout(props: Props) {
  const renderItems = () => {
    if (props.loading) {
      return (
        <>
          {new Array(6).fill(null).map((index, i) => (
            <StackedItemSkeleton key={`IndexStackedItemSkeleton-${i}`} />
          ))}
        </>
      )
    }

    return props.indexes.map((index) => {
      return (
        <TableRow key={index.id} href={RouteNames.IndexDetail.get(index.name)}>
          <TableCell className="font-medium">
            <span className="text-zinc-500">{index.id}</span>
          </TableCell>
          <TableCell className="font-semibold">{index.name}</TableCell>
          <TableCell>
            <span className="flex items-center">
              <span className="mr-1.5 block h-1.5 w-1.5 rounded-full bg-emerald-400" />
              <span className="text-zinc-600 dark:text-zinc-300">{index.region}</span>
            </span>
          </TableCell>
          <TableCell className="text-zinc-600 dark:text-zinc-500">{index.cloud?.toUpperCase()}</TableCell>
          <TableCell>
            <img src={`${process.env.PUBLIC_URL}/static/images/mock/chart.svg`} className="max-w-[240px]" alt="chart" />
          </TableCell>
          <TableCell>
            <Dropdown>
              <DropdownButton plain>
                <EllipsisVertical size={16} />
              </DropdownButton>
              <DropdownMenu>
                <DropdownItem href={RouteNames.IndexDetail.get(index.name)}>View</DropdownItem>
                <DropdownDivider />
                <DropdownItem href={RouteNames.IndexDetailSettings.get(index.name)}>Settings</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>ID</TableHeader>
          <TableHeader>Index Name</TableHeader>
          <TableHeader>Region</TableHeader>
          <TableHeader>Cloud</TableHeader>
          <TableHeader>Activity</TableHeader>
          <TableHeader>Actions</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>{renderItems()}</TableBody>
    </Table>
  )
}
