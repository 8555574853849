import { z } from 'zod'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useNotificationStore } from 'components/common'
import { Form } from 'components/form/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { ForgotPasswordFormSchema } from '../schema/forgot-password.schema'
import { resetPasswordApi } from '../services/reset-password.services'

const formSchema = ForgotPasswordFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  Email = 'email',
}

const formFields = [{ name: FieldNames.Email, label: 'Email', type: 'email', autoFocus: true }]

function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const defaultValues: FormSchema = {
    email: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      const response = await resetPasswordApi(values)
      const { success } = response.data

      if (!success) {
        setMessage('Your password could not be reset. Please try again.', {
          type: 'error',
        })
        return
      }

      setMessage(
        'A password reset link has been sent to your email. Please check your inbox and follow the instructions.',
        {
          type: 'success',
          duration: 10000,
        }
      )
    } catch (e: any) {
      console.log(e)
      setMessage(e?.message ?? GENERIC_ERROR_MESSAGE, {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Heading level={1}>Forgot your password?</Heading>

      <Form
        formFields={formFields}
        formSchema={formSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        loading={loading}
        actionBtnText="Reset Password"
        inlineErrorMessages
        actionBtnProps={{ color: 'zinc', className: 'w-full' }}
      />

      <Button outline href={RouteNames.Login}>
        Back to Login
      </Button>
    </>
  )
}

export default ForgotPasswordPage
