import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useDocsPanel } from 'context/docs-provider'
import { FileCode2 } from 'lucide-react'
import { useIndexStore } from '../store'
import { CreateIndexDialog } from './create-index-dialog'

export function IndexesHeader() {
  const { openDocsPanel } = useDocsPanel()
  const { indexes } = useIndexStore()

  if (!indexes.length) {
    return null
  }

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
            <CreateIndexDialog />
          </>
        }
        description="An index organizes and holds multiple vectors in Vectroid."
      >
        Indexes
      </Heading>
    </>
  )
}
