import { RouteObject } from 'react-router-dom'

import { RouteNames } from 'const/navigation'
import DatasetsPage from './pages'

const routes: RouteObject[] = [
  {
    path: RouteNames.Datasets,
    element: <DatasetsPage />,
  },
]

export default routes
