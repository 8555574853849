import { z } from 'zod'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/form/form'
import { Field, FieldTypes } from 'components/form/types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DatasetCreateSchema } from '../schema/dataset-create.schema'

const formSchema = DatasetCreateSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  name = 'name',
  description = 'description',
}

const formFields: Field[] = [
  {
    name: FieldNames.name,
    label: 'Name',
    type: FieldTypes.Text,
    autoFocus: true,
  },
  {
    name: FieldNames.description,
    label: 'Description',
    type: FieldTypes.Text,
  },
]

export function DatasetCreateForm({ onCancel }: { onCancel?: () => void }) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()

  const navigate = useNavigate()

  const defaultValues: FormSchema = {
    [FieldNames.name]: '',
    [FieldNames.description]: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)
      // setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      // const response = await createIndexApi(values)

      // setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
      // onCancel?.()
      // fetchIndexes(true)
      // navigate(RouteNames.IndexDetailConsole.get(response.data.indexName, 'insert'))
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create Dataset"
    />
  )
}
