import { CollectionDynamicVariables } from 'modules/indexes'
import { CodeSnippetTemplateIds } from '../const/code-snippets'

/**
 * This function replaces the variables in the text with the values provided in the variables object
 *
 * @param text
 * @param variables
 * @returns {String}
 */
export function replaceStaticVariables(text: string, variables: CollectionDynamicVariables): string {
  // @ts-ignore
  return text.replace(/{{\s*([^\s{}]+)\s*}}/g, (_: any, variable: keyof CollectionDynamicVariables) => {
    const value = variables?.[variable]

    return value ?? ''
  })
}

export const isEscapeCharBodyLang = (snippet: CodeSnippetTemplateIds) =>
  [
    CodeSnippetTemplateIds.Curl,
    CodeSnippetTemplateIds.Clojure,
    CodeSnippetTemplateIds.CSharpHttpClient,
    CodeSnippetTemplateIds.CSharpRestSharp,
    CodeSnippetTemplateIds.Go,
    CodeSnippetTemplateIds.JavaAsyncHTTPClient,
    CodeSnippetTemplateIds.JavaNetHttp,
    CodeSnippetTemplateIds.JavaOkHttp,
    CodeSnippetTemplateIds.JavaUnirest,
    CodeSnippetTemplateIds.KotlinOkHttp,
    CodeSnippetTemplateIds.OCamlCohttp,
    CodeSnippetTemplateIds.PHPCurl,
    CodeSnippetTemplateIds.PythonNative,
    CodeSnippetTemplateIds.RHttr,
    CodeSnippetTemplateIds.RubyNative,
  ].includes(snippet)
