import { Navigate, RouteObject } from 'react-router-dom'

import { RouteNames } from 'const/navigation'

import CreateIndex from './pages/create-index'
import Indexes from './pages/index'
import IndexDetail from './pages/index-detail'

// Index Detail sub routes
import AccessDatabasePage from './pages/index-detail/access-database'
import ConsolePage from './pages/index-detail/console'
import SettingsPage from './pages/index-detail/settings'

const routes: RouteObject[] = [
  {
    path: RouteNames.Indexes,
    element: <Indexes />,
  },
  {
    path: RouteNames.CreateIndex,
    element: <CreateIndex />,
  },
  {
    path: RouteNames.IndexDetail.name,
    element: <IndexDetail />,
    children: [
      {
        index: true,
        element: <Navigate to={RouteNames.IndexDetailConsole.name} replace />,
      },
      {
        path: RouteNames.IndexDetailConsole.name,
        element: <ConsolePage />,
      },
      {
        path: RouteNames.IndexDetailAccessDB.name,
        element: <AccessDatabasePage />,
      },

      {
        path: RouteNames.IndexDetailSettings.name,
        element: <SettingsPage />,
      },
    ],
  },
]

export default routes
