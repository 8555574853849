import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { appConfig } from 'const/app-config'
import { useAuthStore } from 'modules/auth'
import { refreshTokenApi } from 'modules/auth/services'
import { handleGoogleSSOLogin } from 'modules/auth/utils/google-sso'

interface ApiError {
  message: string
  status: number
}

const axiosClient = axios.create()

/**
 * @param url {String}
 * @param method {Method}
 * @param data T
 * @param options {ApiCallOptions} - Options for the API call
 * @returns {Promise<ApiResponse<T>>}
 */
export async function apiClient<T, K>(path: string, method: string, data: T): Promise<AxiosResponse<K>> {
  const config: AxiosRequestConfig<T> = {
    baseURL: `${appConfig.API_BASE_URL}${path}`,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    return axiosClient(config)
  } catch (error: any) {
    const status = error?.response?.status || 500
    const message = error?.response?.data?.message || 'An unexpected error occurred'
    throw { message, status } as ApiError
  }
}

axiosClient.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}

    const token = useAuthStore?.getState()?.token
    if (token) {
      config.headers!['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // TODO: Read refresh token request from headers
    const errorMessage = error.response.data?.message

    if (errorMessage?.includes('Refresh token is requested')) {
      try {
        const _refreshToken = useAuthStore?.getState()?.refreshToken
        if (_refreshToken) {
          // Refresh token
          const response = await refreshTokenApi({ refreshToken: _refreshToken })
          const { token, refreshToken, apiKey, account } = response.data
          useAuthStore?.getState().login(token, refreshToken, apiKey, account)

          // Retry the original request
          return axiosClient.request(error.config)
        }
      } catch (e) {
        console.error('Error while refreshing token:', e)
      }
    }

    if (errorMessage?.includes('Invalid JWT token') || errorMessage?.includes('Invalid refresh token')) {
      const authMethod = useAuthStore?.getState()?.account?.authMethod
      useAuthStore?.getState().logout()
      if (authMethod === 'google') {
        handleGoogleSSOLogin()
      } else if (authMethod === 'email') {
        window.location.replace('/console/logout')
      }

      return
    }

    console.log('Error:', error.response)
    return Promise.reject(error)
  }
)
