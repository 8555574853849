import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { cn } from 'lib/utils'
import { ChevronDown, CodeIcon } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { CodeLanguage, CodeSnippetLanguages, Snippet } from '../const/code-snippets'

import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { Text } from 'components/catalyst/text'
import { CodeEditor } from 'components/code-editor'
import { CodeLanguageIcon } from 'components/code-lang-icon'
import { useNotificationStore } from 'components/common'
import { useCodeGenerator } from '../hooks/use-code-generator'

type Props = {
  bodyObject: string
}

export function CodeSnippetDropdown(props: {
  children: ReactNode
  classes?: string
  handleItemClick: (item: Snippet) => void
  isTab?: boolean
}) {
  const renderItems = () => {
    return CodeSnippetLanguages.map((item) => (
      <DropdownItem
        onClick={() => {
          props.handleItemClick(item)
        }}
        key={item.id}
      >
        <CodeLanguageIcon item={item} className="mr-4" />
        {item.label}
      </DropdownItem>
    ))
  }

  return (
    <Dropdown>
      <DropdownButton
        plain={props.isTab ? true : undefined}
        outline={props.isTab ? undefined : true}
        className={props.classes}
      >
        {props.isTab ? (
          <Text className="flex items-center gap-x-2 font-normal">
            <CodeIcon size={16} /> {props.children}
            <ChevronDown size={16} />
          </Text>
        ) : (
          <>
            {props.children}
            <ChevronDown size={16} />
          </>
        )}
      </DropdownButton>

      <DropdownMenu anchor="bottom start">{renderItems()}</DropdownMenu>
    </Dropdown>
  )
}

export function CodeGenerator(props: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const setMessage = useNotificationStore((state) => state.setMessage)

  const { handleSnippetChange, generatedCode, selectedSnippet } = useCodeGenerator(props.bodyObject)

  const handleItemClick = (item: Snippet) => {
    try {
      handleSnippetChange(item)
      setIsOpen(true)
    } catch (e: any) {
      console.log(e)
      setMessage(e.message, { type: 'error' })
    }
  }

  return (
    <div className="flex justify-end">
      <CodeSnippetDropdown isTab={false} handleItemClick={handleItemClick} classes="!px-2 !py-1.5 !text-xs">
        <>
          <img
            src={`${process.env.PUBLIC_URL}/static/icons/lang/json.svg`}
            alt="icon"
            title={`JSON icon`}
            width="16"
            className={'dark:invert dark:filter'}
          />
          JSON
        </>
      </CodeSnippetDropdown>

      {selectedSnippet && (
        <Dialog open={isOpen} onClose={setIsOpen} className="!w-[860px] !max-w-full">
          <DialogTitle>Generate Client Code</DialogTitle>
          <DialogDescription>
            <CodeSnippetDropdown isTab={false} handleItemClick={handleItemClick} classes="w-full !justify-start">
              <span className="flex flex-1">
                <img
                  src={`${process.env.PUBLIC_URL}/static/icons/lang/${selectedSnippet?.lang}.svg`}
                  alt="icon"
                  title={`${selectedSnippet?.label} icon`}
                  width="24"
                  className={cn('mr-2', {
                    'dark:invert dark:filter': [CodeLanguage.Curl, CodeLanguage.Shell].includes(selectedSnippet.lang),
                  })}
                />
                {selectedSnippet?.label}
              </span>
            </CodeSnippetDropdown>
          </DialogDescription>
          <DialogBody>
            <CodeEditor code={generatedCode} />
          </DialogBody>
        </Dialog>
      )}
    </div>
  )
}
