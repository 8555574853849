import { Banner } from 'components/banner'

import { cn } from 'lib/utils'
import { useEffect } from 'react'
import useNotificationStore from './store'

export { useNotificationStore }

export function NotificationBanner() {
  const { type, duration, message, title, visible, dismissable, actionBtns, reset } = useNotificationStore()

  useEffect(() => {
    if (!visible) {
      reset()
    }
  }, [visible, reset])

  useEffect(() => {
    if (type === 'success' && visible) {
      const timer = setTimeout(() => {
        reset()
      }, duration)

      return () => clearTimeout(timer)
    }
  }, [type, visible, reset])

  return (
    <div
      className={cn('relative right-8 z-[2000] w-full max-w-[420px] transition-all sm:fixed sm:-ml-[260px]', {
        '-top-full opacity-0 max-sm:hidden': !visible,
        'opacity-1 bottom-8': visible,
      })}
    >
      <Banner
        type={type}
        title={title}
        message={message}
        actionBtns={actionBtns}
        onDismiss={reset}
        dismissable={dismissable}
      />
    </div>
  )
}
