import { z } from 'zod'
import { CreateAccountFormSchema } from '../schema'

import { Heading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { useNotificationStore } from 'components/common'
import { Form } from 'components/form/form'
import { Field } from 'components/form/types'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SocialLogins } from '../components/social-logins'
import { createAccountApi } from '../services'
import { useAuthStore } from '../store'

const formSchema = CreateAccountFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  Email = 'email',
  Name = 'name',
  Password = 'password',
}

const formFields: Field[] = [
  { name: FieldNames.Email, label: 'Email', autoFocus: true, type: 'email' },
  { name: FieldNames.Name, label: 'Full Name' },
  { name: FieldNames.Password, label: 'Password', type: 'password' },
]

function RegisterPage() {
  const [loading, setLoading] = useState(false)
  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()
  const { setMessage } = useNotificationStore()

  const defaultValues: FormSchema = {
    name: '',
    email: '',
    password: '',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)

      const response = await createAccountApi(values)
      const { account } = response.data

      loginAction(null, null, null, account)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      console.log(e)
      setMessage(e?.message ?? GENERIC_ERROR_MESSAGE, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full">
      <Heading level={1}>Create an Account</Heading>
      <Text className="mb-4">
        Already have an account?{' '}
        <b className="font-normal text-sky-600 hover:text-sky-700 dark:text-sky-400 dark:hover:text-zinc-300">
          <Link to={RouteNames.Login}>Sign in.</Link>
        </b>
      </Text>

      <Form
        formSchema={formSchema}
        formFields={formFields}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        loading={loading}
        actionBtnText="Create Account"
        inlineErrorMessages
        actionBtnProps={{ color: 'zinc', className: 'w-full' }}
      />

      <SocialLogins />
    </div>
  )
}

export default RegisterPage
