import { javascript } from '@codemirror/lang-javascript'

import CodeMirror from '@uiw/react-codemirror'
import { useTheme } from 'context/theme-provider'
import { getCodeEditorTheme } from 'modules/api-playground/utils/console-theme'
import { Card } from './catalyst/card'
import { CopyButton } from './copy-button'

type Props = {
  code: string
  readonly?: boolean
}

export function CodeEditor(props: Props) {
  const { theme } = useTheme()
  const codeEditorTheme = getCodeEditorTheme(theme)

  return (
    <Card className="code-editor relative">
      <CopyButton className="!absolute right-4 top-4 z-[2000]" text={props.code} />

      <CodeMirror
        theme={codeEditorTheme}
        value={props.code || ''}
        extensions={[javascript()]}
        data-color-mode={theme as 'light' | 'dark'}
        readOnly={props.readonly}
        style={{
          lineHeight: '3.5!important',
        }}
      />
    </Card>
  )
}
