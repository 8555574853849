import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, UseFormReturn } from 'react-hook-form'
import { z } from 'zod'

import { Banner } from 'components/banner'
import { Button } from 'components/catalyst/button'
import { Input } from 'components/catalyst/input'
import { Select } from 'components/catalyst/select'
import { useNotificationStore } from 'components/common'
import { Form as UIForm, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Field, FieldTypes } from './types'

interface Props {
  formSchema: any
  formFields: Field[]
  onSubmit: (values: any, form: UseFormReturn<any>) => void
  defaultValues: Record<string, any>
  loading?: boolean
  actionBtnText?: string
  onCancel?: () => void
  inlineErrorMessages?: boolean
  actionBtnProps?: any
}

export function Form(props: Props) {
  const messageProps = useNotificationStore()

  const form = useForm<z.infer<typeof props.formSchema>>({
    resolver: zodResolver(props.formSchema),
    defaultValues: props.defaultValues,
  })

  function getInputElement(formField: Field, field: any) {
    if (formField.fieldType === FieldTypes.Select) {
      return (
        <Select disabled={props.loading} {...field}>
          {formField.options.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      )
    }

    return <Input disabled={props.loading} {...field} type={formField.type} autoFocus={formField.autoFocus} />
  }

  function renderForm() {
    return props.formFields.map((formField) => (
      <FormField
        key={formField.name}
        control={form.control}
        name={formField.name as any}
        render={({ field }) => {
          const inputElement = getInputElement(formField, field)

          return (
            <FormItem>
              <FormLabel className="!select-text" dangerouslySetInnerHTML={{ __html: formField.label }} />

              <FormControl>{inputElement}</FormControl>

              <FormMessage className="text-xs" />
            </FormItem>
          )
        }}
      />
    ))
  }

  const isLoading = props.loading || form.formState.isSubmitting

  return (
    <UIForm {...form}>
      <form
        onSubmit={form.handleSubmit((values) => props.onSubmit(values, form))}
        className="w-full space-y-6 sm:max-w-md"
      >
        {renderForm()}

        {props.inlineErrorMessages && messageProps.visible && (
          <Banner title={messageProps.title} type={messageProps.type} message={messageProps.message} />
        )}

        <div className="flex justify-end gap-4">
          {props.onCancel && (
            <Button plain onClick={props.onCancel}>
              Cancel
            </Button>
          )}
          <Button color="amber" type="submit" loading={isLoading} disabled={isLoading} {...props.actionBtnProps}>
            {props.actionBtnText ?? 'Submit'}
          </Button>
        </div>
      </form>
    </UIForm>
  )
}
