import { z } from 'zod'
import { CreateIndexFormSchema } from '../schema'

import { useNotificationStore } from 'components/common'
import { Form } from 'components/form/form'
import { Field, FieldTypes } from 'components/form/types'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createIndexApi } from '../services'
import { useIndexStore } from '../store'

const formSchema = CreateIndexFormSchema

type FormSchema = z.infer<typeof formSchema>

enum FieldNames {
  indexName = 'indexName',
  similarityFunction = 'similarityFunction',
  cloud = 'cloud',
  region = 'region',
}

const formFields: Field[] = [
  { name: FieldNames.indexName, label: 'Index Name', autoFocus: true, type: FieldTypes.Text },
  {
    name: FieldNames.similarityFunction,
    fieldType: FieldTypes.Select,
    options: ['cosine'],
    label: 'Similarity Function',
  },
  { name: FieldNames.cloud, fieldType: FieldTypes.Select, options: ['gcp'], label: 'Cloud' },
  { name: FieldNames.region, fieldType: FieldTypes.Select, options: ['us-east1'], label: 'Region' },
]

export function CreateIndexForm({ onCancel }: { onCancel?: () => void }) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const { fetchIndexes } = useIndexStore()
  const navigate = useNavigate()

  const defaultValues: FormSchema = {
    [FieldNames.indexName]: '',
    [FieldNames.similarityFunction]: 'cosine',
    [FieldNames.cloud]: 'gcp',
    [FieldNames.region]: 'us-east1',
  }

  async function onSubmit(values: FormSchema) {
    try {
      setLoading(true)
      setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      const response = await createIndexApi(values)

      setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
      onCancel?.()
      fetchIndexes(true)
      navigate(RouteNames.IndexDetailConsole.get(response.data.indexName, 'insert'))
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      formSchema={formSchema}
      formFields={formFields}
      defaultValues={defaultValues}
      loading={loading}
      actionBtnText="Create Index"
    />
  )
}
