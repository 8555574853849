import { Heading } from 'components/catalyst/heading'
import { DatasetCreateDialog } from '../components/dataset-create-dialog'
import { DatasetListView } from '../components/dataset-list-view'

function DatasetsPage() {
  return (
    <>
      <Heading
        level={2}
        className="sm:text-lg/8"
        description="Manage your datasets"
        actions={
          <>
            <DatasetCreateDialog />
          </>
        }
      >
        Datasets
      </Heading>

      <DatasetListView />
    </>
  )
}

export default DatasetsPage
