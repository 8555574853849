import { GridLayout } from 'components/common/grid-layout/grid-layout'
import { DashboardLayout, useTheme } from 'context/theme-provider'
import { DatasetEmptyState } from './dataset-empty-state'
import { DatasetItemGrid } from './dataset-item-grid'
import { DatasetStackedLayout } from './dataset-stacked-layout'

export function DatasetListView() {
  const { dashboardLayout } = useTheme()

  const datasets: any[] = [{ id: 1 }, { id: 2 }, { id: 3 }]
  const loading = false

  if (!loading && datasets.length === 0) {
    return <DatasetEmptyState />
  }

  if (dashboardLayout === DashboardLayout.Grid) {
    return (
      <GridLayout loading={loading}>
        {datasets.map((dataset) => (
          <DatasetItemGrid dataset={dataset} key={dataset.id} />
        ))}
      </GridLayout>
    )
  }

  return <DatasetStackedLayout />
}
