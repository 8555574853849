import { apiClient } from 'lib'
import { CheckEmailRequest, CheckEmailResponse, LoginRequest, LoginResponse, RefreshTokenRequest } from '../types'

export function checkEmailApi(values: CheckEmailRequest) {
  return apiClient<CheckEmailRequest, CheckEmailResponse>('/user/email/check', 'POST', values)
}

export function loginApi(values: LoginRequest) {
  return apiClient<LoginRequest, LoginResponse>('/login', 'POST', values)
}

export function refreshTokenApi(values: RefreshTokenRequest) {
  return apiClient<RefreshTokenRequest, LoginResponse>('/token/refresh', 'POST', values)
}
