export enum FieldTypes {
  Text = 'text',
  Select = 'select',
}

export type Field = {
  name: string
  label: string
  autoFocus?: boolean
  type?: string
  fieldType?: FieldTypes
} & FieldProps

export type FieldProps =
  | { fieldType?: FieldTypes.Text; options?: never }
  | { fieldType: FieldTypes.Select; options: string[] }
