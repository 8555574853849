import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import { CodeEditor } from 'components/code-editor'
import { CodeLanguageIcon } from 'components/code-lang-icon'
import { CodeSnippetDropdown } from 'modules/api-playground/components/code-generator'
import { useEffect, useState } from 'react'
import { CodeSnippetLanguages, CodeSnippetTemplateIds, Snippet } from '../const/code-snippets'
import { useCodeGenerator } from '../hooks/use-code-generator'

type Props = {
  bodyObject: string
  defaultTabs?: CodeSnippetTemplateIds[]
  defaultOptionaltab?: CodeSnippetTemplateIds
}

const DEFAULT_SHOWN_TABS = [
  CodeSnippetTemplateIds.JavaScriptFetch,
  CodeSnippetTemplateIds.JavaNetHttp,
  CodeSnippetTemplateIds.Go,
  CodeSnippetTemplateIds.PythonNative,
]

const DEFAULT_OPTIONAL_TAB = CodeSnippetTemplateIds.Curl

export function SdkClientPlayground(props: Props) {
  const defaultTabs = props.defaultTabs || DEFAULT_SHOWN_TABS
  const defaultOptionaltab = props.defaultOptionaltab || DEFAULT_OPTIONAL_TAB

  const navItems = CodeSnippetLanguages.filter((item) => defaultTabs.includes(item.id))
    .map((item) => ({ ...item, label: item.label.split('-')[0] }))
    .sort((a, b) => DEFAULT_SHOWN_TABS.indexOf(a.id) - defaultTabs.indexOf(b.id))

  const optionalNavItem = CodeSnippetLanguages.find((item) => item.id === defaultOptionaltab) as Snippet

  const [tab, setTab] = useState(DEFAULT_SHOWN_TABS[0])
  const [tabs, setTabs] = useState<Snippet[]>([...navItems, optionalNavItem])

  const { handleSnippetChange, generatedCode } = useCodeGenerator(props.bodyObject)

  function handleAdditionalTabClick(item: Snippet) {
    if (!DEFAULT_SHOWN_TABS.includes(item.id)) {
      setTabs([...navItems, item])
    }
    setTab(item.id)
    handleSnippetChange(item)
  }

  useEffect(() => {
    const newTab = tabs.find((item) => item.id === tab) as Snippet
    if (newTab) {
      handleSnippetChange(newTab)
    }
  }, [tab])

  return (
    <>
      <Navbar>
        <NavbarSection>
          {tabs?.map((navItem) => (
            <NavbarItem onClick={() => setTab(navItem.id)} current={navItem.id === tab} key={navItem.id}>
              <CodeLanguageIcon item={navItem} className="-mr-2" />
              {navItem.label}
            </NavbarItem>
          ))}
          <CodeSnippetDropdown isTab handleItemClick={handleAdditionalTabClick}>
            Other Clients
          </CodeSnippetDropdown>
        </NavbarSection>
        <NavbarSpacer />
      </Navbar>

      <CodeEditor readonly code={generatedCode} />
    </>
  )
}
