import { useAuthStore } from 'modules/auth'
import { useConsoleStore } from 'modules/indexes'
import { useState } from 'react'
import { Snippet, Snippets } from '../const/code-snippets'
import { isEscapeCharBodyLang, replaceStaticVariables } from '../utils/playground-utils'

export function useCodeGenerator(bodyObject: string) {
  const [selectedSnippet, setSelectedSnippet] = useState<Snippet | null>(null)
  const [generatedCode, setGeneratedCode] = useState('')

  const { account, apiKey } = useAuthStore()
  const { activeCollection } = useConsoleStore()

  const handleSnippetChange = (item: Snippet) => {
    const url = `https://${account?.endpoint}.api.vectroid.com/api/v1${activeCollection?.endpoint}`
    const parsedBody = JSON.stringify(JSON.parse(bodyObject))
    const snippet = Snippets[item.id]
    const codeSnippet = replaceStaticVariables(snippet, {
      url,
      apiKey: apiKey!,
      body: isEscapeCharBodyLang(item.id) ? parsedBody.replaceAll('"', '\\"') : parsedBody,
      endpoint: `/api/v1${activeCollection?.endpoint}`,
      host: `${account?.endpoint}.api.vectroid.com`,
      contentLength: parsedBody.length.toString(),
    })
    setGeneratedCode(codeSnippet)
    setSelectedSnippet(item)
  }

  return {
    handleSnippetChange,
    generatedCode,
    selectedSnippet,
  }
}
