import { Input } from 'components/catalyst/input'
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox'
import { DashboardLayout, useTheme } from 'context/theme-provider'
import { LayoutGridIcon, ListIcon } from 'lucide-react'
import { useIndexStore } from '../store'

export function IndexesHeaderToolbar() {
  const { indexes } = useIndexStore()
  const { dashboardLayout, setDashboardLayout } = useTheme()

  if (!indexes.length) {
    return null
  }

  return (
    <div className="mb-6 flex gap-6">
      <Input name="search" placeholder="Search&hellip;" aria-label="Search indexes..." className="flex-[5]" />

      <Listbox
        name="status"
        defaultValue={dashboardLayout}
        onChange={(value) => setDashboardLayout(value)}
        className="flex-1"
      >
        <ListboxOption className="w-32" value={DashboardLayout.Stacked}>
          <ListIcon size={16} />
          <ListboxLabel>Stacked layout</ListboxLabel>
        </ListboxOption>
        <ListboxOption className="w-32" value={DashboardLayout.Grid}>
          <LayoutGridIcon size={16} />
          <ListboxLabel>Grid layout</ListboxLabel>
        </ListboxOption>
      </Listbox>
    </div>
  )
}
