import { createBrowserRouter } from 'react-router-dom'

import apiKeysRoutes from 'modules/api-keys/routes'
import apiPlaygroundRoutes from 'modules/api-playground/routes'
import authRoutes from 'modules/auth/routes'
import datasetRoutes from 'modules/datasets/routes'
import indexRoutes from 'modules/indexes/routes'

import Logout from 'components/logout'
import NotFound from 'components/not-found'
import { RouteNames } from 'const/navigation'
import Root from './root'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      children: [
        ...authRoutes,
        ...indexRoutes,
        ...apiKeysRoutes,
        ...apiPlaygroundRoutes,
        ...datasetRoutes,
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    {
      path: RouteNames.Logout,
      element: <Logout />,
    },
  ],
  {
    basename: '/console',
  }
)

export default router
