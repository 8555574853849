import { Button } from 'components/catalyst/button'
import { Divider } from 'components/catalyst/divider'
import { Heading } from 'components/catalyst/heading'
import { appConfig } from 'const/app-config'
import { ExternalLink } from 'lucide-react'
import { SdkClientPlayground } from 'modules/api-playground/components/sdk-client-playground'
import { CodeSnippetTemplateIds } from 'modules/api-playground/const/code-snippets'
import { useAuthStore } from 'modules/auth'
import { CreateIndexDialog } from './create-index-dialog'

const CREATE_INDEX_BODY_OBJECT = `{
  "indexName": "<YOUR_INDEX_NAME>",
  "similarityFunction": "cosine",
  "cloud": "gcp",
  "region": "us-east1"
}`

export function IndexListEmptyState() {
  const { account } = useAuthStore()

  return (
    <div className="mx-auto w-full max-w-2xl">
      <Heading description="Get started by creating an index to store your vectors. You can either create your first index on the console, or using the API.">
        Welcome {account?.name},
      </Heading>

      <CreateIndexDialog>
        <div className="text-left">
          <Heading className="!text-lg" level={2} divider={false} description="Create an index on the console">
            Option 1
          </Heading>
          <span className="relative block rounded-lg border-2 border-dashed border-neutral-300 p-12 text-center hover:border-neutral-400 dark:border-neutral-700 dark:hover:border-neutral-500">
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 48 48"
              aria-hidden="true"
              className="mx-auto h-12 w-12 dark:text-neutral-300"
            >
              <path
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="mt-4 block text-sm font-medium text-neutral-700 dark:text-neutral-500">
              You don't have any indexes yet
            </span>
            <span className="mt-2 block text-sm font-medium text-neutral-700 dark:text-neutral-500">
              Create a new one
            </span>
          </span>
        </div>
      </CreateIndexDialog>

      <Divider className="mx-auto mb-6 mt-12 max-w-2xl" />

      <div className="mx-auto mt-6 max-w-2xl">
        <Heading
          className="!text-lg"
          level={2}
          description="Create an index using the API"
          divider={false}
          actions={
            <Button plain href={appConfig.DOCS_BASE_URL} target="_blank">
              API Docs <ExternalLink size={16} />
            </Button>
          }
        >
          Option 2
        </Heading>
        <div className="-mt-4">
          <SdkClientPlayground
            defaultTabs={[CodeSnippetTemplateIds.JavaScriptFetch, CodeSnippetTemplateIds.Go]}
            bodyObject={CREATE_INDEX_BODY_OBJECT}
          />
        </div>
      </div>
    </div>
  )
}
