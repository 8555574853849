import { useNotificationStore } from 'components/common'
import { INDEX_API_PLAYGROUND_COLLECTION } from 'const/api-collection'
import { executePlaygroundCode, IndexResponseType } from 'lib/index'
import { QueryConsole } from 'modules/api-playground/components/query-console'
import { ApiCollectionList } from 'modules/indexes/components/api-collection-list'
import { useIndexStore } from 'modules/indexes/store'

import { useConsoleStore } from 'modules/indexes/store/console-store'

import { useState } from 'react'
import { useParams } from 'react-router-dom'

function ConsolePage() {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(null)
  const setMessage = useNotificationStore((state) => state.setMessage)

  const { activeCollection } = useConsoleStore()

  const params = useParams()

  const { getIndexByName } = useIndexStore()
  const indexDetail = getIndexByName(params.indexName!)

  function setResponse(data: IndexResponseType) {
    setResult(JSON.stringify(data, null, 3))
  }

  const onExecute = async (code: string) => {
    try {
      setLoading(true)

      const response = await executePlaygroundCode(code, activeCollection?.endpoint!)
      response && setResponse(response.data as unknown as IndexResponseType)
    } catch (e: any) {
      console.log(e)
      setResponse(e)
      setMessage(e.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full flex-1">
      <ApiCollectionList collectionList={INDEX_API_PLAYGROUND_COLLECTION} />
      <QueryConsole
        activeCollection={activeCollection}
        dynamicVariables={{ indexName: indexDetail?.name, indexId: indexDetail?.id }}
        onExecute={onExecute}
        loading={loading}
        result={result}
      />
    </div>
  )
}

export default ConsolePage
