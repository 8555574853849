import { Button } from 'components/catalyst/button'
import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { FolderPlus } from 'lucide-react'
import { useState } from 'react'
import { DatasetCreateForm } from './dataset-create-form'

export function DatasetCreateDialog() {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button className="max-sm:w-full" color="amber" onClick={() => setIsOpen(true)}>
        <FolderPlus size={18} strokeWidth={2.5} />
        Create New Dataset
      </Button>

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Create new Dataset</DialogTitle>
        <DialogDescription>
          Please provide a name for your new dataset. This name will help you identify the dataset in the future.
        </DialogDescription>
        <DialogBody>
          <DatasetCreateForm />
        </DialogBody>
      </Dialog>
    </>
  )
}
