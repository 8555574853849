import { apiClient } from 'lib'
import { LoginResponse, ResetPasswordRequest, ResetPasswordResponse, SetupNewPasswordRequest } from '../types'

export function resetPasswordApi(values: ResetPasswordRequest) {
  return apiClient<ResetPasswordRequest, ResetPasswordResponse>('/user/password/reset', 'POST', values)
}

export function setupNewPassword(values: SetupNewPasswordRequest) {
  return apiClient<SetupNewPasswordRequest, LoginResponse>('/user/password/create', 'POST', values)
}
