import { apiClient } from 'lib'
import {
  CreateIndexRequest,
  CreateIndexResponse,
  DeleteIndexRequest,
  DeleteIndexResponse,
  DescribeIndexRequest,
  IndexVectorRequest,
  IndexVectorResponse,
  ListIndexesRequest,
  ListIndexesResponse,
  QueryIndexRequest,
  QueryIndexResponse,
} from '../types'

export function createIndexApi(values: CreateIndexRequest) {
  return apiClient<CreateIndexRequest, CreateIndexResponse>('/indexes', 'POST', values)
}

export function queryIndexApi(values: QueryIndexRequest) {
  return apiClient<QueryIndexRequest, QueryIndexResponse>('/query', 'POST', values)
}

export function describeIndexApi(values: DescribeIndexRequest) {
  return apiClient<DescribeIndexRequest, IndexVectorResponse>('/describe', 'POST', values)
}

export function indexVectorApi(values: IndexVectorRequest) {
  return apiClient<IndexVectorRequest, IndexVectorResponse>('/index', 'POST', values)
}

export function listIndexesApi() {
  return apiClient<ListIndexesRequest, ListIndexesResponse>('/indexes', 'GET', {})
}

export function deleteIndexApi(values: DeleteIndexRequest) {
  return apiClient<DeleteIndexRequest, DeleteIndexResponse>('indexes', 'DELETE', values)
}
