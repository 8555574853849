import { RouteNames } from 'const/navigation'
import { cn } from 'lib/utils'
import { useId } from 'react'
import { Link } from 'react-router-dom'

export function Logo({
  iconOnly,
  ...props
}: React.ComponentPropsWithoutRef<'svg'> & { iconOnly?: boolean; className?: string; disabled?: boolean }) {
  let id = useId()
  return (
    <Link
      to={RouteNames.Dashboard}
      className={cn(
        'app-logo inline-block p-2',
        {
          'pointer-events-none': props.disabled,
        },
        props.className
      )}
    >
      <svg
        id={id}
        width="115"
        height="22"
        {...props}
        viewBox="0 0 115 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden dark:block"
      >
        <mask
          id="mask0_39_5"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="27"
          height="22"
        >
          <path
            d="M13.7034 9.84202C13.9839 10.2716 14.459 10.5289 14.9644 10.5289C16.1748 10.5289 16.8975 9.15514 16.2253 8.12869L11.366 0.706815C11.3306 0.650218 11.2902 0.596194 11.2472 0.544742C11.227 0.519017 11.2042 0.495863 11.1815 0.47271C11.1714 0.46242 11.1613 0.45213 11.1512 0.441839C10.57 -0.14728 9.61478 -0.14728 9.03358 0.441839C9.02347 0.45213 9.01336 0.46242 9.00325 0.47271C8.98051 0.495863 8.9603 0.519017 8.93755 0.544742C8.8946 0.596194 8.85416 0.650218 8.81879 0.706815L0.257401 13.7883C-0.414774 14.8148 0.30794 16.1886 1.51836 16.1886C2.02628 16.1886 2.49883 15.9313 2.77932 15.5017L10.0924 4.33157L13.7009 9.84202H13.7034Z"
            fill="#D946EF"
          />
          <path
            d="M22.4922 13.1015H14.8279C14.32 13.1015 13.8475 13.3587 13.567 13.7883C12.8948 14.8148 13.6175 16.1886 14.8279 16.1886H25.325C26.1614 16.1886 26.8412 15.4965 26.8412 14.645C26.8412 14.3312 26.7502 14.0405 26.591 13.7986L18.0094 0.691379C17.4105 -0.224457 16.0864 -0.224457 15.4875 0.691379C15.1489 1.21104 15.1489 1.88505 15.4875 2.40471L22.4922 13.1015Z"
            fill="#FACC15"
          />
          <path
            d="M26.7451 19.5972C26.4646 19.1676 25.9896 18.9103 25.4842 18.9103H7.20405L11.3255 12.6153C11.6667 12.093 11.7021 11.4061 11.3685 10.8788C10.7747 9.94235 9.43537 9.93463 8.83142 10.8582L3.08761 19.6307C2.93852 19.8699 2.8526 20.1529 2.8526 20.4565C2.8526 21.308 3.53236 22 4.36879 22H25.4816C26.6921 22 27.4148 20.6262 26.7426 19.5998L26.7451 19.5972Z"
            fill="#5EEAD4"
          />
        </mask>
        <g mask="url(#mask0_39_5)" className="origin-left scale-[0.85] transform">
          <rect width="27" height="22" fill="url(#paint0_linear_39_5)" />
        </g>
        <g className={cn('inline-block transition-all', iconOnly ? 'opacity-0' : 'opacity-100')}>
          <path
            d="M107.712 18.1484C106.864 18.1484 106.067 17.934 105.319 17.5051C104.586 17.0615 103.997 16.4182 103.552 15.5753C103.106 14.7324 102.883 13.712 102.883 12.5142V12.1593C102.883 10.9615 103.106 9.94112 103.552 9.0982C103.997 8.25529 104.586 7.61941 105.319 7.19056C106.052 6.74693 106.85 6.52511 107.712 6.52511C108.359 6.52511 108.898 6.60644 109.329 6.76911C109.775 6.91699 110.134 7.10923 110.407 7.34584C110.68 7.58244 110.888 7.83384 111.032 8.10002H111.42V2.31055H114.137V17.8379H111.463V16.507H111.075C110.831 16.921 110.45 17.2981 109.933 17.6382C109.43 17.9784 108.69 18.1484 107.712 18.1484ZM108.531 15.7084C109.365 15.7084 110.062 15.4348 110.623 14.8877C111.183 14.3257 111.463 13.5124 111.463 12.4477V12.2259C111.463 11.1611 111.183 10.3552 110.623 9.80803C110.076 9.24608 109.379 8.96511 108.531 8.96511C107.698 8.96511 107.001 9.24608 106.44 9.80803C105.88 10.3552 105.6 11.1611 105.6 12.2259V12.4477C105.6 13.5124 105.88 14.3257 106.44 14.8877C107.001 15.4348 107.698 15.7084 108.531 15.7084Z"
            fill="white"
          />
          <path
            d="M98.5367 17.8379V6.83565H101.253V17.8379H98.5367ZM99.8948 5.5491C99.4062 5.5491 98.9894 5.38643 98.6445 5.0611C98.3139 4.73577 98.1486 4.30692 98.1486 3.77455C98.1486 3.24219 98.3139 2.81334 98.6445 2.488C98.9894 2.16267 99.4062 2 99.8948 2C100.398 2 100.815 2.16267 101.145 2.488C101.476 2.81334 101.641 3.24219 101.641 3.77455C101.641 4.30692 101.476 4.73577 101.145 5.0611C100.815 5.38643 100.398 5.5491 99.8948 5.5491Z"
            fill="white"
          />
          <path
            d="M91.2866 18.1484C90.2231 18.1484 89.2674 17.9266 88.4194 17.483C87.5715 17.0393 86.9032 16.396 86.4146 15.5531C85.9259 14.7102 85.6816 13.6972 85.6816 12.5142V12.1593C85.6816 10.9763 85.9259 9.9633 86.4146 9.12039C86.9032 8.27747 87.5715 7.6342 88.4194 7.19056C89.2674 6.74693 90.2231 6.52511 91.2866 6.52511C92.3502 6.52511 93.3059 6.74693 94.1538 7.19056C95.0018 7.6342 95.6701 8.27747 96.1587 9.12039C96.6474 9.9633 96.8917 10.9763 96.8917 12.1593V12.5142C96.8917 13.6972 96.6474 14.7102 96.1587 15.5531C95.6701 16.396 95.0018 17.0393 94.1538 17.483C93.3059 17.9266 92.3502 18.1484 91.2866 18.1484ZM91.2866 15.664C92.1202 15.664 92.8101 15.3905 93.3562 14.8433C93.9023 14.2814 94.1754 13.4828 94.1754 12.4477V12.2259C94.1754 11.1907 93.9023 10.3995 93.3562 9.85239C92.8244 9.29045 92.1346 9.00948 91.2866 9.00948C90.4531 9.00948 89.7632 9.29045 89.2171 9.85239C88.671 10.3995 88.3979 11.1907 88.3979 12.2259V12.4477C88.3979 13.4828 88.671 14.2814 89.2171 14.8433C89.7632 15.3905 90.4531 15.664 91.2866 15.664Z"
            fill="white"
          />
          <path
            d="M78.8929 17.8379V6.83565H81.566V8.07784H81.9541C82.1122 7.6342 82.3709 7.30886 82.7302 7.10183C83.1038 6.8948 83.535 6.79129 84.0236 6.79129H85.3171V9.27566H83.9805C83.2907 9.27566 82.723 9.4679 82.2774 9.85239C81.8319 10.2221 81.6092 10.7988 81.6092 11.5826V17.8379H78.8929Z"
            fill="white"
          />
          <path
            d="M74.0196 17.8379C73.3154 17.8379 72.7405 17.616 72.295 17.1724C71.8638 16.714 71.6482 16.1077 71.6482 15.3535V9.14257H68.975V6.83565H71.6482V3.41964H74.3645V6.83565H77.2964V9.14257H74.3645V14.8655C74.3645 15.3091 74.5657 15.5309 74.9681 15.5309H77.0377V17.8379H74.0196Z"
            fill="white"
          />
          <path
            d="M63.5352 18.1484C62.5004 18.1484 61.5591 17.9266 60.7111 17.483C59.8775 17.0393 59.2164 16.396 58.7278 15.5531C58.2391 14.7102 57.9948 13.6899 57.9948 12.492V12.1815C57.9948 10.9837 58.2391 9.9633 58.7278 9.12039C59.2164 8.27747 59.8775 7.6342 60.7111 7.19056C61.5591 6.74693 62.5004 6.52511 63.5352 6.52511C64.5556 6.52511 65.4323 6.70996 66.1653 7.07965C66.8982 7.44935 67.4875 7.95953 67.933 8.6102C68.3929 9.24608 68.6947 9.97069 68.8384 10.784L66.2084 11.3608C66.1509 10.9171 66.0215 10.5178 65.8203 10.1629C65.6191 9.80803 65.3317 9.52705 64.958 9.32002C64.5987 9.11299 64.146 9.00948 63.5999 9.00948C63.0537 9.00948 62.5579 9.13517 62.1124 9.38657C61.6812 9.62318 61.3363 9.98548 61.0776 10.4735C60.8333 10.9467 60.7111 11.5308 60.7111 12.2259V12.4477C60.7111 13.1427 60.8333 13.7342 61.0776 14.2222C61.3363 14.6954 61.6812 15.0577 62.1124 15.3091C62.5579 15.5457 63.0537 15.664 63.5999 15.664C64.4191 15.664 65.0371 15.4496 65.4539 15.0208C65.885 14.5771 66.1581 14.0004 66.2731 13.2906L68.9031 13.9339C68.7163 14.7176 68.3929 15.4348 67.933 16.0855C67.4875 16.7214 66.8982 17.2242 66.1653 17.5939C65.4323 17.9636 64.5556 18.1484 63.5352 18.1484Z"
            fill="white"
          />
          <path
            d="M51.8045 18.1484C50.741 18.1484 49.7996 17.9192 48.9804 17.4608C48.1756 16.9876 47.5432 16.3295 47.0833 15.4866C46.6378 14.6289 46.415 13.6233 46.415 12.4699V12.2037C46.415 11.0502 46.6378 10.052 47.0833 9.20911C47.5288 8.35141 48.154 7.69335 48.9588 7.23493C49.7637 6.76171 50.6978 6.52511 51.7614 6.52511C52.8105 6.52511 53.7231 6.76911 54.4992 7.25711C55.2753 7.73032 55.8789 8.39578 56.3101 9.25348C56.7412 10.0964 56.9568 11.0798 56.9568 12.2037V13.1575H49.1744C49.2032 13.9117 49.4762 14.5254 49.9936 14.9986C50.511 15.4718 51.1434 15.7084 51.8907 15.7084C52.6524 15.7084 53.2129 15.5383 53.5722 15.1982C53.9315 14.8581 54.2046 14.481 54.3914 14.0669L56.6119 15.2648C56.4107 15.6493 56.1161 16.0707 55.728 16.5291C55.3544 16.9728 54.8513 17.3573 54.219 17.6826C53.5866 17.9931 52.7818 18.1484 51.8045 18.1484ZM49.196 11.0724H54.1974C54.1399 10.4365 53.8884 9.92633 53.4429 9.54184C53.0117 9.15736 52.444 8.96511 51.7398 8.96511C51.0068 8.96511 50.4248 9.15736 49.9936 9.54184C49.5625 9.92633 49.2966 10.4365 49.196 11.0724Z"
            fill="white"
          />
          <path
            d="M37.8373 17.8379L34 2.31055H36.9319L40.1224 15.9524H40.4243L43.6148 2.31055H46.5467L42.7094 17.8379H37.8373Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_39_5"
            x1="10.125"
            y1="-1.34694"
            x2="21.1091"
            y2="22.0192"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A2E0FD" />
            <stop offset="1" stopColor="#40BFF8" />
          </linearGradient>
        </defs>
      </svg>

      <svg
        width="115"
        height="22"
        viewBox="0 0 115 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="block dark:hidden"
      >
        <g clipPath="url(#clip0_98_19)">
          <mask
            id="mask0_98_19"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="27"
            height="22"
          >
            <path
              d="M13.7034 9.84202C13.9839 10.2716 14.459 10.5289 14.9644 10.5289C16.1748 10.5289 16.8975 9.15514 16.2253 8.12869L11.366 0.706815C11.3306 0.650218 11.2902 0.596194 11.2472 0.544742C11.227 0.519017 11.2042 0.495863 11.1815 0.47271C11.1714 0.46242 11.1613 0.45213 11.1512 0.441839C10.57 -0.14728 9.61478 -0.14728 9.03358 0.441839C9.02347 0.45213 9.01336 0.46242 9.00325 0.47271C8.98051 0.495863 8.9603 0.519017 8.93755 0.544742C8.8946 0.596194 8.85416 0.650218 8.81879 0.706815L0.257401 13.7883C-0.414774 14.8148 0.30794 16.1886 1.51836 16.1886C2.02628 16.1886 2.49883 15.9313 2.77932 15.5017L10.0924 4.33157L13.7009 9.84202H13.7034Z"
              fill="#D946EF"
            />
            <path
              d="M22.4922 13.1015H14.8279C14.32 13.1015 13.8475 13.3587 13.567 13.7883C12.8948 14.8148 13.6175 16.1886 14.8279 16.1886H25.325C26.1614 16.1886 26.8412 15.4965 26.8412 14.645C26.8412 14.3312 26.7502 14.0405 26.591 13.7986L18.0094 0.691394C17.4105 -0.224442 16.0864 -0.224442 15.4875 0.691394C15.1489 1.21105 15.1489 1.88506 15.4875 2.40472L22.4922 13.1015Z"
              fill="#FACC15"
            />
            <path
              d="M26.745 19.5972C26.4645 19.1676 25.9895 18.9103 25.4841 18.9103H7.20399L11.3254 12.6153C11.6666 12.093 11.702 11.4061 11.3684 10.8788C10.7746 9.94236 9.43531 9.93464 8.83136 10.8582L3.08755 19.6307C2.93846 19.8699 2.85254 20.1529 2.85254 20.4565C2.85254 21.308 3.5323 22 4.36873 22H25.4815C26.692 22 27.4147 20.6262 26.7425 19.5998L26.745 19.5972Z"
              fill="#5EEAD4"
            />
          </mask>
          <g mask="url(#mask0_98_19)">
            <path d="M27 0H0V22H27V0Z" fill="url(#paint0_radial_98_19)" />
          </g>

          <g className={cn('transition-all', iconOnly ? 'opacity-0' : 'opacity-100')}>
            <path
              d="M107.712 18.1484C106.864 18.1484 106.067 17.934 105.319 17.5051C104.586 17.0615 103.997 16.4182 103.552 15.5753C103.106 14.7324 102.883 13.712 102.883 12.5142V12.1593C102.883 10.9615 103.106 9.94112 103.552 9.0982C103.997 8.25529 104.586 7.61941 105.319 7.19056C106.052 6.74693 106.85 6.52511 107.712 6.52511C108.359 6.52511 108.898 6.60644 109.329 6.76911C109.775 6.91699 110.134 7.10923 110.407 7.34584C110.68 7.58244 110.888 7.83384 111.032 8.10002H111.42V2.31055H114.137V17.8379H111.463V16.507H111.075C110.831 16.921 110.45 17.2981 109.933 17.6382C109.43 17.9784 108.69 18.1484 107.712 18.1484ZM108.531 15.7084C109.365 15.7084 110.062 15.4348 110.623 14.8877C111.183 14.3257 111.463 13.5124 111.463 12.4477V12.2259C111.463 11.1611 111.183 10.3552 110.623 9.80803C110.076 9.24608 109.379 8.96511 108.531 8.96511C107.698 8.96511 107.001 9.24608 106.44 9.80803C105.88 10.3552 105.6 11.1611 105.6 12.2259V12.4477C105.6 13.5124 105.88 14.3257 106.44 14.8877C107.001 15.4348 107.698 15.7084 108.531 15.7084Z"
              fill="#09090B"
            />
            <path
              d="M98.5368 17.8379V6.83565H101.253V17.8379H98.5368ZM99.8949 5.5491C99.4063 5.5491 98.9895 5.38643 98.6446 5.0611C98.314 4.73577 98.1487 4.30692 98.1487 3.77455C98.1487 3.24219 98.314 2.81334 98.6446 2.488C98.9895 2.16267 99.4063 2 99.8949 2C100.398 2 100.815 2.16267 101.145 2.488C101.476 2.81334 101.641 3.24219 101.641 3.77455C101.641 4.30692 101.476 4.73577 101.145 5.0611C100.815 5.38643 100.398 5.5491 99.8949 5.5491Z"
              fill="#09090B"
            />
            <path
              d="M91.2866 18.1484C90.2231 18.1484 89.2674 17.9266 88.4194 17.483C87.5715 17.0393 86.9032 16.396 86.4146 15.5531C85.9259 14.7102 85.6816 13.6972 85.6816 12.5142V12.1593C85.6816 10.9763 85.9259 9.96328 86.4146 9.12037C86.9032 8.27745 87.5715 7.63418 88.4194 7.19054C89.2674 6.74691 90.2231 6.52509 91.2866 6.52509C92.3502 6.52509 93.3059 6.74691 94.1538 7.19054C95.0018 7.63418 95.6701 8.27745 96.1587 9.12037C96.6474 9.96328 96.8917 10.9763 96.8917 12.1593V12.5142C96.8917 13.6972 96.6474 14.7102 96.1587 15.5531C95.6701 16.396 95.0018 17.0393 94.1538 17.483C93.3059 17.9266 92.3502 18.1484 91.2866 18.1484ZM91.2866 15.664C92.1202 15.664 92.8101 15.3905 93.3562 14.8433C93.9023 14.2814 94.1754 13.4828 94.1754 12.4477V12.2259C94.1754 11.1907 93.9023 10.3995 93.3562 9.85237C92.8244 9.29043 92.1346 9.00946 91.2866 9.00946C90.4531 9.00946 89.7632 9.29043 89.2171 9.85237C88.671 10.3995 88.3979 11.1907 88.3979 12.2259V12.4477C88.3979 13.4828 88.671 14.2814 89.2171 14.8433C89.7632 15.3905 90.4531 15.664 91.2866 15.664Z"
              fill="#09090B"
            />
            <path
              d="M78.8928 17.8379V6.83562H81.5659V8.07781H81.954C82.1121 7.63417 82.3708 7.30883 82.7301 7.1018C83.1037 6.89477 83.5349 6.79126 84.0235 6.79126H85.317V9.27563H83.9804C83.2906 9.27563 82.7229 9.46787 82.2773 9.85236C81.8318 10.2221 81.6091 10.7988 81.6091 11.5826V17.8379H78.8928Z"
              fill="#09090B"
            />
            <path
              d="M74.0197 17.8379C73.3155 17.8379 72.7406 17.616 72.2951 17.1724C71.8639 16.714 71.6483 16.1077 71.6483 15.3535V9.14255H68.9751V6.83563H71.6483V3.41962H74.3646V6.83563H77.2965V9.14255H74.3646V14.8655C74.3646 15.3091 74.5658 15.5309 74.9682 15.5309H77.0378V17.8379H74.0197Z"
              fill="#09090B"
            />
            <path
              d="M63.5353 18.1484C62.5005 18.1484 61.5592 17.9266 60.7112 17.483C59.8776 17.0393 59.2165 16.396 58.7279 15.5531C58.2392 14.7102 57.9949 13.6899 57.9949 12.492V12.1815C57.9949 10.9837 58.2392 9.96328 58.7279 9.12037C59.2165 8.27745 59.8776 7.63418 60.7112 7.19054C61.5592 6.74691 62.5005 6.52509 63.5353 6.52509C64.5557 6.52509 65.4324 6.70994 66.1654 7.07963C66.8983 7.44933 67.4876 7.95951 67.9331 8.61018C68.393 9.24606 68.6948 9.97067 68.8385 10.784L66.2085 11.3608C66.151 10.9171 66.0216 10.5178 65.8204 10.1629C65.6192 9.80801 65.3318 9.52703 64.9581 9.32C64.5988 9.11297 64.1461 9.00946 63.6 9.00946C63.0538 9.00946 62.558 9.13515 62.1125 9.38655C61.6813 9.62316 61.3364 9.98546 61.0777 10.4735C60.8334 10.9467 60.7112 11.5308 60.7112 12.2259V12.4477C60.7112 13.1427 60.8334 13.7342 61.0777 14.2222C61.3364 14.6954 61.6813 15.0577 62.1125 15.3091C62.558 15.5457 63.0538 15.664 63.6 15.664C64.4192 15.664 65.0372 15.4496 65.454 15.0208C65.8851 14.5771 66.1582 14.0004 66.2732 13.2906L68.9032 13.9339C68.7164 14.7176 68.393 15.4348 67.9331 16.0855C67.4876 16.7214 66.8983 17.2242 66.1654 17.5939C65.4324 17.9636 64.5557 18.1484 63.5353 18.1484Z"
              fill="#09090B"
            />
            <path
              d="M51.8045 18.1484C50.741 18.1484 49.7996 17.9192 48.9804 17.4608C48.1756 16.9876 47.5432 16.3295 47.0833 15.4866C46.6378 14.6289 46.415 13.6233 46.415 12.4699V12.2037C46.415 11.0502 46.6378 10.052 47.0833 9.20909C47.5288 8.35139 48.154 7.69333 48.9588 7.23491C49.7637 6.76169 50.6978 6.52509 51.7614 6.52509C52.8105 6.52509 53.7231 6.76909 54.4992 7.25709C55.2753 7.7303 55.8789 8.39576 56.3101 9.25346C56.7412 10.0964 56.9568 11.0798 56.9568 12.2037V13.1575H49.1744C49.2032 13.9117 49.4762 14.5254 49.9936 14.9986C50.511 15.4718 51.1434 15.7084 51.8907 15.7084C52.6524 15.7084 53.2129 15.5383 53.5722 15.1982C53.9315 14.8581 54.2046 14.481 54.3914 14.0669L56.6119 15.2648C56.4107 15.6493 56.1161 16.0707 55.728 16.5291C55.3544 16.9728 54.8513 17.3573 54.219 17.6826C53.5866 17.9931 52.7818 18.1484 51.8045 18.1484ZM49.196 11.0724H54.1974C54.1399 10.4365 53.8884 9.92631 53.4429 9.54182C53.0117 9.15734 52.444 8.96509 51.7398 8.96509C51.0068 8.96509 50.4248 9.15734 49.9936 9.54182C49.5625 9.92631 49.2966 10.4365 49.196 11.0724Z"
              fill="#09090B"
            />
            <path
              d="M37.8373 17.8379L34 2.31055H36.9319L40.1224 15.9524H40.4243L43.6148 2.31055H46.5467L42.7094 17.8379H37.8373Z"
              fill="#09090B"
            />
          </g>
        </g>
        <defs>
          <radialGradient
            id="paint0_radial_98_19"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(15.6171 10.3361) rotate(64.8224) scale(12.9096)"
          >
            <stop stopColor="#78D4FF" />
            <stop offset="1" stopColor="#0CA5E9" />
          </radialGradient>
          <clipPath id="clip0_98_19">
            <rect width="115" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Link>
  )
}
