import { CopyIcon } from 'lucide-react'
import { useState } from 'react'
import { Button } from './catalyst/button'

type Props = {
  text: string
  className?: string
}

export function CopyButton({ text, ...props }: Props) {
  const [btnText, setBtnText] = useState('Copy')

  function handleCopy() {
    navigator?.clipboard?.writeText(text)

    setBtnText('Copied!')
    setTimeout(() => {
      setBtnText('Copy')
    }, 2000)
  }

  return (
    <Button onClick={handleCopy} outline className={props.className}>
      <CopyIcon size={16} />
      {btnText}
    </Button>
  )
}
